import React, { useState, useEffect } from 'react';
import './ToggleSwitch.sass';

interface ToggleSwitchProps {
  onChange?: (checked: boolean) => void;
  defaultChecked?: boolean;
  disabled?: boolean;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ onChange, defaultChecked = false, disabled = false }) => {
  const [isChecked, setIsChecked] = useState(defaultChecked);

  useEffect(() => {
    setIsChecked(defaultChecked);
  }, [defaultChecked]);

  const handleToggle = () => {
    if (!disabled) {
      const newValue = !isChecked;
      setIsChecked(newValue);
      onChange?.(newValue);
    }
  };

  return (
    <label className='toggle-switch'>
      <input type='checkbox' checked={isChecked} onChange={handleToggle} disabled={disabled} />
      <span className='slider'></span>
    </label>
  );
};

export default ToggleSwitch;
